import i18next from 'i18next'

import de from './resources/de'
import en from './resources/en'
import fr from './resources/fr'
import it from './resources/it'

const languages = require('@/i18n/languages')

i18next
    .init({
        debug: (process.env.NODE_ENV === 'development'),
        lng: languages.defaultLanguage,
        fallbackLng: languages.defaultLanguage,
        whitelist: languages.allowedLanguages,
        defaultNS: 'general',
        resources: {
            de,
            en,
            fr,
            it
        }
    })

export default i18next
