import { HTTP } from '@/http-common'

const getDefaultState = () => {
    return {
        language: null,
        maintenance: false,
        lastInteraction: Date.now(),
        burgerNavOpen: false,
        affiliate: {
            parameter: null,
            expiringDate: null,
            id: null,
            goCautionOptionDeactivated: false
        },
        browserCompatibility: {
            compatible: null,
            reason: null
        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    language: (state) => state.language,
    isStateOutdated: (state) => {
        const timeDiff = 72 * 60 * 60 * 1000 // 72h
        return (Date.now() - state.lastInteraction > timeDiff)
    },
    burgerNavOpen: (state) => state.burgerNavOpen,
    affiliate: (state) => state.affiliate,
    browserCompatibility: (state) => state.browserCompatibility
}

// mutations
const mutations = {
    changeLanguage: (state, newLanguage) => {
        state.language = newLanguage
    },
    setMaintenance: (state, maintenance) => {
        state.maintenance = maintenance
    },
    setLastInteraction: (state, lastInteraction) => {
        state.lastInteraction = lastInteraction
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    },
    changeBurgerNavOpen(state, newValue) {
        state.burgerNavOpen = newValue
    },
    setAffiliate(state, affiliate) {
        state.affiliate = affiliate
    },
    setBrowserCompatibility: (state, browserCompatibility) => {
        state.browserCompatibility = browserCompatibility
    }
}

// actions
const actions = {
    checkHealth: async ({ state, commit }) => {
        let maintenance = false
        try {
            await HTTP({ url: 'health', baseURL: process.env.BASE_URL + 'api/' })
        } catch (error) {
            maintenance = true
        }

        if (maintenance !== state.maintenance) {
            commit('setMaintenance', maintenance)
        }
    },
    loadAffiliate: async ({ commit }, affiliateData) => {
        try {
            const response = await HTTP.get('/affiliate/' + affiliateData.affiliateUrlParameter)
            const now = new Date()
            commit('setAffiliate', {
                parameter: affiliateData.affiliateUrlParameter,
                expiringDate: now.setDate(now.getDate() + response.data.data.attributionPeriod),
                id: affiliateData.affiliateOrderId,
                goCautionOptionDeactivated: response.data.data.goCautionOptionDeactivated
            })
        } catch (error) {
            console.log('getAffiliate() catch()', error, error.response)
        }
    },
    updateLastInteraction: ({ commit }) => {
        commit('setLastInteraction', Date.now())
    },
    resetState: (context) => {
        context.commit('resetState')
    },
    changeBurgerNavOpen: (context, value) => {
        context.commit('changeBurgerNavOpen', value)
    },
    setBrowserCompatibility: (context, value) => {
        context.commit('setBrowserCompatibility', value)
    },
    setAffiliate: (context, value) => {
        context.commit('setAffiliate', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
