<template>
    <div id="app" :class="routeClasses">
        <RouterView/>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import credittrustSocial from '@/assets/images/credittrust_social.png'
import env from '@/utils/env'

export default {
    name: 'App',
    data() {
        return {
            orderUuid: null
        }
    },
    metaInfo() {
        return {
            meta: [
                { property: 'description', content: this.$t('general:meta.description') },
                // OpenGraph data (Most widely used): http://ogp.me/#types
                { property: 'og:url', content: this.metaTags.url },
                { property: 'og:description', content: this.$t('general:meta.description') },
                { property: 'og:image', content: this.metaTags.image },
                // Google / Schema.org markup:
                { itemprop: 'description', content: this.$t('general:meta.description') },
                { itemprop: 'image', content: this.metaTags.image }
            ]
        }
    },
    computed: {
        routeClasses() {
            const classes = []
            if (!this.maintenance) {
                for (const route of this.$route.matched) {
                    if (route.name) {
                        classes.push('route-' + route.name)
                    }
                }
            }
            return classes
        },
        metaTags() {
            const image = env('VUE_APP_SOCIAL_BASE_URL') + credittrustSocial
            const url = env('VUE_APP_SOCIAL_BASE_URL')

            return { image, url }
        },
        ...mapState('app', [
            'maintenance'
        ]),
        ...mapGetters('app', [
            'isStateOutdated'
        ])
    },
    methods: {
        ...mapActions('app', {
            checkHealth: 'checkHealth',
            updateLastInteraction: 'updateLastInteraction'
        }),
        ...mapActions('userdata', {
            resetUserdataState: 'resetState'
        })
    },
    mounted() {
        if (this.isStateOutdated) {
            this.resetUserdataState()
            this.updateLastInteraction()
        }

        this.checkHealth()
        window.setInterval(this.checkHealth, 10000)
    }
}
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
