<template>
    <nav class="language-selector">
        <span class="active-language">{{ activeLanguage.toUpperCase() }}</span>
        <MDIcon icon="chevron-down" class="chevron"/>
        <ul class="available-languages">
            <li v-for="language in availableLanguages" :key="language">
                <router-link :to="{params: {locale: language}}">
                    <span>{{ language.toUpperCase() }}</span>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import { allowedLanguages } from '@/i18n/languages'
import { mapGetters } from 'vuex'

export default {
    name: 'LanguageSelector',
    computed: {
        availableLanguages() {
            return allowedLanguages
        },
        ...mapGetters('app', {
            activeLanguage: 'language'
        })
    }
}
</script>
