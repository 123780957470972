<template>
    <svg class="md-icon" viewBox="0 0 24 24">
        <path fill="currentColor" :d="currentIcon"/>
    </svg>
</template>

<script>
import {
    mdiAlert,
    mdiArrowLeft,
    mdiArrowRight,
    mdiCached,
    mdiChat,
    mdiCheckCircleOutline,
    mdiChevronDown,
    mdiChevronRight,
    mdiDownload,
    mdiTimer,
    mdiWindowClose,
    mdiCreditCardOutline,
    mdiCellphone,
    mdiReceipt,
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxMarkedCircleOutline
} from '@mdi/js'

export default {
    name: 'MDIcon',
    props: {
        icon: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            icons: {
                cellphone: mdiCellphone,
                receipt: mdiReceipt,
                alert: mdiAlert,
                cached: mdiCached,
                download: mdiDownload,
                chat: mdiChat,
                'timer-outline': mdiTimer,
                'chevron-right': mdiChevronRight,
                'chevron-down': mdiChevronDown,
                'window-close': mdiWindowClose,
                'arrow-left': mdiArrowLeft,
                'arrow-right': mdiArrowRight,
                'check-circle-outline': mdiCheckCircleOutline,
                'credit-card-outline': mdiCreditCardOutline,
                'checkbox-blank-circle-outline': mdiCheckboxBlankCircleOutline,
                'checkbox-marked-circle-outline': mdiCheckboxMarkedCircleOutline
            }
        }
    },
    computed: {
        currentIcon() {
            return (this.icons[this.icon]) ? this.icons[this.icon] : this.icons['mdi-alert']
        }
    }
}
</script>
