<template>
    <div v-if="!maintenance" class="wrapper">
        <BrowserIncompatibilityBanner v-if="showBrowserIncompatibilityBanner"/>
        <CHeader/>
        <CMain/>
    </div>
    <Maintenance v-else/>
</template>

<script>
import BrowserIncompatibilityBanner from '@/components/BrowserIncompatibilityBanner'
import CHeader from '@/components/layout/CHeader'
import CMain from '@/components/layout/CMain'
import Maintenance from '@/views/Maintenance'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'I18nView',
    components: {
        BrowserIncompatibilityBanner,
        CHeader,
        CMain,
        Maintenance
    },
    computed: {
        ...mapState('app', [
            'maintenance'
        ]),
        ...mapGetters('app', [
            'browserCompatibility'
        ]),
        browserIncompatibilityLabel() {
            return 'browserIncompatibility:' + this.browserCompatibility.reason
        },
        showBrowserIncompatibilityBanner() {
            let show = false
            for (const route of this.$route.matched) {
                if (route.name === 'cert-personal-data-name' || route.name === 'cert-personal-data-address' || route.name === 'cert-identification') {
                    if (!this.browserCompatibility.compatible) {
                        show = true
                        break
                    }
                }
            }
            return show
        }
    }
}
</script>
