import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
// Modules
import app from './modules/app'
import userdata from './modules/userdata'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key: 'vuex_credittrust'
})

export default new Vuex.Store({
    plugins: [
        vuexLocalStorage.plugin
    ],
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        userdata
    }
})
