<template>
    <header class="header">
        <div class="inside">
            <div class="logo" @click="navigationOpen = false">
                <RouterLink :to="{ name: 'root' }">
                    <img src="@/assets/images/credittrust_logo.svg" alt="CreditTrust">
                </RouterLink>
            </div>
            <div class="navigation" v-if="!logoOnly">
                <LanguageSelector v-if="!minimalHeader"/>
                <HelpLink v-if="minimalHeader"/>
                <HelpSelector />
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import HelpLink from '@/components/HelpLink'
import LanguageSelector from '@/components/LanguageSelector'
import HelpSelector from '@/components/HelpSelector'

export default {
    name: 'CHeader',
    components: {
        HelpLink,
        LanguageSelector,
        HelpSelector
    },
    props: {
        logoOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('app', [
            'burgerNavOpen'
        ]),
        ...mapGetters('app', {
            activeLanguage: 'language'
        }),
        minimalHeader() {
            let minimalHeader = false
            for (const route of this.$route.matched) {
                if (route.name === 'get-certificate' || route.name === 'start') {
                    minimalHeader = true
                    break
                }
            }

            return minimalHeader
        },
        navigationOpen: {
            get() {
                return this.burgerNavOpen
            },
            set(value) {
                this.changeBurgerNavOpen(value)
            }
        }
    },
    watch: {
        navigationOpen: function(newVal) {
            const className = 'nav-open'
            if (newVal) {
                document.body.classList.add(className)
            } else {
                document.body.classList.remove(className)
            }
        }
    },
    methods: {
        ...mapActions('app', [
            'changeBurgerNavOpen'
        ])
    }
}
</script>
