import { HTTP } from '@/http-common'

const getDefaultState = () => {
    return {
        debuggerMode: false,
        orderUuid: null,
        referrerUrl: null,
        salutation: '',
        firstname: '',
        lastname: '',
        dateOfBirth: '',
        email: '',
        phoneNumber: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        oldStreet: '',
        oldHouseNumber: '',
        oldZip: '',
        oldCity: '',
        movedInPast: false,
        enforcements: null,
        acceptTerms: false,
        idnowIframeUrl: '',
        idnowIdent: '',
        certificateUrl: '',
        receiptUrl: '',
        contactSubject: '',
        contactMessage: '',
        invoiceType: null,
        goCautionOfferInterest: false,
        orderStatus: {
            fiveYearDebtEnforcement: null,
            creditStatus: {
                crifChecked: false,
                crifFailed: false,
                intrumChecked: false,
                intrumFailed: false,
                creditChecked: false,
                creditFailed: false,
                creditApproved: false,
                oldAddressCheckPossible: false,
                checkedOldAddress: false,
                failedCreditProvider: null
            },
            idnowStatus: {
                idnowResult: null,
                idnowReason: null,
                allowIdnowRetry: false,
                idnowFailed: false,
                idnowSuccess: false,
                timestamp: null
            },
            certificateStatus: {
                certificateCreated: null
            },
            paymentStatus: {
                readyForPayment: false,
                paymentCompleted: false,
                waitingForEmailConfirmation: false
            }
        },
        previousIdnowStatus: {
            timestamp: null
        },
        debuggerOrderStatus: null
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getData: (state) => (field) => {
        return state[field]
    },
    orderStatus: (state) => {
        if (state.debuggerMode && process.env.NODE_ENV !== 'production') {
            return state.debuggerOrderStatus
        } else {
            return state.orderStatus
        }
    }
}

// mutations
const mutations = {
    mutate: (state, payload) => {
        state[payload.property] = payload.value
    },
    resetDebuggerOrderStatus: (state) => {
        state.debuggerOrderStatus = null
        state.debuggerMode = false
    },
    syncDebuggerOrderStatus: (state) => {
        state.debuggerOrderStatus = state.orderStatus
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    },
    updatePreviousIdnowStatusTimestamp: (state) => {
        state.previousIdnowStatus.timestamp = state.orderStatus.idnowStatus.timestamp
    }
}

// actions
const actions = {
    setData: (context, payload) => {
        context.commit('mutate', payload)
    },
    resetState: (context) => {
        context.commit('resetState')
    },
    fetchOrderStatus: async (context) => {
        context.commit('updatePreviousIdnowStatusTimestamp')
        const response = await HTTP.get('/order/' + context.getters.getData('orderUuid') + '/status')

        if (response.data.data) {
            context.commit('mutate', {
                property: 'orderStatus',
                value: response.data.data
            })
        }
    },
    syncDebuggerOrderStatus: (context) => {
        context.commit('syncDebuggerOrderStatus')
    },
    resetDebuggerOrderStatus: (context) => {
        context.commit('resetDebuggerOrderStatus')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
