<template>
    <nav class="help-selector">
        <span class="label">{{ $t('header:nav.support') }}</span>
        <MDIcon icon="chevron-down" class="chevron"/>
        <ul class="available-links">
            <li>
                <a :href="$t('header:nav.faq.url')" target="_blank" class="active">
                    <span>{{ $t('header:nav.faq.label') }}</span>
                </a>
            </li>
            <li>
                <a :href="$t('header:nav.contact.url')" target="_blank" class="active">
                    <span>{{ $t('header:nav.contact.label') }}</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>

export default {
    name: 'HelpSelector'
}
</script>
