<template>
    <div class="wrapper">
        <CHeader logo-only/>
        <div class="main">
            <div class="inside">
                <div class="page-maintenance status-page">
                    <StatusScreen :title="$t('maintenance:title')" :lead-text="$t('maintenance:leadText')">
                        <template v-slot:icon>
                            <img src="@/assets/images/icons/icon_tools.svg" class="icon" alt="Icon">
                        </template>
                    </StatusScreen>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CHeader from '@/components/layout/CHeader'
import StatusScreen from '@/components/StatusScreen'

export default {
    name: 'Maintenance',
    components: {
        CHeader,
        StatusScreen
    }
}
</script>
