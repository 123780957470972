<template>
    <div class="help-link">
        <a href="mailto:support@credittrust.ch" target="_blank" class="active">
            <MDIcon icon="chat" class="icon"/>
            <span>{{ $t('header:help') }}</span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'HelpLink'
}
</script>
