import Vue from 'vue'
import VueI18Next from '@panter/vue-i18next'
import { configure } from 'vee-validate'
import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'

import i18next from '@/i18n'
import store from '@/store'
import router from '@/router'

import MDIcon from './components/MDIcon'

import App from '@/App'

// global vee-validate settings
configure({
    mode: 'aggressive',
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    },
    defaultMessage: (_, values) => i18next.t(`error:${values._rule_}`, values)
})
// END: global vee-validate settings

Vue.use(VueI18Next)
Vue.use(VTooltip)
Vue.use(VueScrollTo)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

const i18n = new VueI18Next(i18next)

Vue.component('MDIcon', MDIcon)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
