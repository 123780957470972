<template>
    <div class="browser-incompatibility-banner">
        <p v-html="$t(browserIncompatibilityLabel)"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'BrowserIncompatibilityBanner',
    computed: {
        ...mapGetters('app', [
            'browserCompatibility'
        ]),
        browserIncompatibilityLabel() {
            return 'browserIncompatibility:' + this.browserCompatibility.reason
        }
    }
}
</script>
