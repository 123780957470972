<template>
    <div class="status-screen">
        <StatusVisual>
            <template v-slot:icon>
                <slot name="icon"/>
            </template>
        </StatusVisual>

        <h2 v-if="title" class="title" v-html="title" />

        <p v-if="leadText" class="lead-text" v-html="leadText" />

        <slot name="content-footer"/>
    </div>
</template>

<script>
import StatusVisual from '@/components/StatusVisual'

export default {
    name: 'StatusScreen',
    components: {
        StatusVisual
    },
    props: {
        title: String,
        leadText: String
    }
}
</script>
