import Vue from 'vue'
import Router from 'vue-router'

import i18next from '@/i18n'
import store from '@/store'
// Views
import I18nView from '@/views/I18nView'

const languages = require('@/i18n/languages')

Vue.use(Router)

const routes = [
    {
        path: '/coming-soon',
        name: 'coming-soon',
        redirect: {
            name: 'root'
        }
    },
    {
        path: '/',
        name: 'root',
        beforeEnter: async (to, from, next) => {
            await store.restored

            if (!store.state.app.language) {
                const browserLanguage = navigator.language
                let newLanguage = languages.defaultLanguage

                if (browserLanguage && languages.allowedLanguages.includes(browserLanguage.substring(0, 2))) {
                    newLanguage = browserLanguage.substring(0, 2)
                }

                store.commit('app/changeLanguage', newLanguage)
            }

            const routeName = store.state.userdata.orderUuid ? 'returning' : 'cert-personal-data-name'

            next({
                name: routeName,
                params: {
                    locale: store.state.app.language
                }
            })
        }
    },
    {
        path: '/:locale(' + languages.allowedLanguages.join('|') + ')',
        component: I18nView,
        redirect: {
            name: 'start'
        },
        children: [
            {
                path: 'start',
                name: 'start',
                redirect: {
                    name: 'cert-personal-data-name'
                }
            },
            {
                path: 'returning',
                name: 'returning',
                component: () => import(/* webpackChunkName: "returning" */ '@/views/Returning.vue')
            },
            {
                path: 'get-certificate',
                name: 'get-certificate',
                component: () => import(/* webpackChunkName: "get-certificate" */ '@/views/GetCertificate'),
                children: [
                    {
                        path: 'personal-data',
                        name: 'cert-personal-data',
                        component: () => import(/* webpackChunkName: "cert-personal-data" */ '@/views/get-certificate/01_PersonalData'),
                        children: [
                            {
                                path: 'name',
                                name: 'cert-personal-data-name',
                                component: () => import(/* webpackChunkName: "cert-personal-data-name" */ '@/views/get-certificate/01-personal-data/01_CName')
                            },
                            {
                                path: 'address',
                                name: 'cert-personal-data-address',
                                component: () => import(/* webpackChunkName: "cert-personal-data-address" */ '@/views/get-certificate/01-personal-data/02_CAddress')
                            }
                        ]
                    },
                    {
                        path: 'identification',
                        name: 'cert-identification',
                        component: () => import(/* webpackChunkName: "cert-identification" */ '@/views/get-certificate/02_Identification')
                    },
                    {
                        path: 'payment/:status?',
                        name: 'cert-payment',
                        component: () => import(/* webpackChunkName: "cert-payment" */ '@/views/get-certificate/03_Payment')
                    },
                    {
                        path: 'download',
                        name: 'cert-download',
                        component: () => import(/* webpackChunkName: "cert-download" */ '@/views/get-certificate/04_Download')
                    }
                ]
            },
            {
                path: 'error',
                name: 'general-error',
                component: () => import(/* webpackChunkName: "general-error" */ '@/views/GeneralError')
            },
            {
                path: 'certificate-error',
                name: 'certificate-error',
                component: () => import(/* webpackChunkName: "certificate-error" */ '@/views/CertificateError')
            }
        ]
    },
    {
        path: '/:locale(' + languages.allowedLanguages.join('|') + ')?/*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
    }
]

const router = new Router({
    mode: 'history',
    base: (process.env.BASE_URL) ? process.env.BASE_URL : '/',
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-exact',
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored

    const newLanguage = to.params.locale

    if (newLanguage) {
        if (newLanguage !== i18next.language) {
            i18next.changeLanguage(newLanguage)
        }
        if (newLanguage !== store.state.app.language) {
            store.commit('app/changeLanguage', newLanguage)
        }
    }

    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 200)

    next()
})

export default router
