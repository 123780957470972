<template>
    <div class="main">
        <div class="inside" :class="{ 'no-padding-bottom': isManagementPage }">
            <RouterView/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CMain',
    computed: {
        isManagementPage() {
            for (const route of this.$route.matched) {
                if (route.name === 'management_de' || route.name === 'management_fr') {
                    return true
                }
            }
            return false
        }
    }
}
</script>
