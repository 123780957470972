<template>
    <div class="status-visual">
        <div class="visual">
            <slot name="icon"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusVisual'
}
</script>
